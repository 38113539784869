import React, { useEffect } from 'react';

import { Layout, SEO } from '../components';

const Success = ({ location }) => {

  useEffect(() => {
    const fetchSession = async () => {

    };
    fetchSession();

  }, []);

  return (
    <Layout>
      <SEO title="Success!" />
      <article className="content-section">
        <div className="container">
          <h2 className="content-h2">Success!</h2>
          <p className="paragraph">
            Thanks for purchasing!
            <br />
            <br />
            Check your email address for password.
          </p>
        </div>
      </article>
    </Layout>
  );
};

export default Success;
